@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    body{
        font-family: 'Cardo', serif;
        scroll-behavior: smooth;
        /* background-color: #8F1D21; */
    }
    html{
        font-size: 16px;
    }
    h1{
        @apply text-[50px] ;
    }

    h2{
        @apply text-[36px];
    }

    h3{
        @apply text-[22px];
    }

    p{
        @apply text-[16px];
    }
}

@layer components{
    .header-hover{
        @apply text-red border-solid border-b-[2px] border-red font-bold ;
    }

    .petal{
        background-image : url('../public/images/petal.png');
    }

    .section1-background {
        background-image: url('../public/images/section1.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* backdrop-filter: light-gray 50%; */
    }

    .tick-symbol {
        background-image:url('../public/images/tick.svg');
        background-repeat: no-repeat;
    }

    .hing-bowl{
        background-image: url('../public/images/hing_bowl.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .book_back{
        background-image: url('../public/images/book_background.jpg');
        z-index: 1;
    }

    .rotate_3d {
        transform: rotate3d(1, 2, -1, 45deg);
        /* box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px; */
        box-shadow: rgba(	255, 211, 69, 0.4) 5px 5px, rgba(	255, 211, 69, 0.3) 10px 10px, rgba(	255, 211, 69, 0.2) 15px 15px, rgba(	255, 211, 69, 0.1) 20px 20px, rgba(	255, 211, 69, 0.05) 25px 25px;
    }

    .about_back{
        background-image: url('../public/images/book_background.jpg');
    }

    @keyframes flipup {
        from {
            transform: rotateY(90deg)
        }
        to {
            transform: rotateY(0deg)
        }
    }

    @keyframes fadeup  {
        from {
            transform: translate(0px, 40px);
            opacity: 0;
        }
        to {
            transform: translate(0px, 0px);
            opacity: 1;
        }
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(-8%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }

    .flipUp {
        animation: flipup 2s linear ;
    }

    .fadeUp{
        animation: fadeup 3s linear 1s;
    }

    .contactBack{
        background-image: url(../public/images/contact_back.jpg);
    }

    .inshadow{
        filter: drop-shadow(2px 4px 6px #8f861d );
    }

    .bounce{
        animation: bounce 1.5s infinite;
    }

    .arrowBack1{
        background-image: url('../public/images/arrowd.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .arrowBack2{
        background-image: url('../public/images/arrowu.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}



.slick-slide > div {
    margin: 0px 0.75rem;
    display: flex;
}

.section1 .slick-dots {
    padding-bottom: 1.5rem;
}

.section1 .slick-dots li button::before {
    font-size: 0.75rem;
    color: #fff;
    opacity: 0.7;
}

.section1 .slick-dots li.slick-active button::before {
    color: #FFD345;
    opacity: 1;
}

.section3 .slick-dots li button::before {
    font-size: 0.75rem;
    color: #fff;
    opacity: 0.7;
}

.section3 .slick-dots li.slick-active button::before {
    color: #FFD345;
    opacity: 1;
}

.section4 .slick-dots {
    background-color: #FFD345 ;
    padding-bottom: 1.5rem;
}

.section4 .slick-dots li button::before {
    font-size: 0.75rem;
    color: #fff;
    opacity: 0.7;
}

.section4 .slick-dots li.slick-active button::before {
    color: #8F1D21 ;
    opacity: 1;
}

.browse .slick-dots{
    background-color:#8F1D21  ;
}
.browse .slick-dots li button::before {
    font-size: 0.75rem;
    color: #fff;
    opacity: 0.7;
}

.browse .slick-dots li.slick-active button::before {
    color: #FFD345;
    opacity: 1;
}

.slick-prev:before{
    font-size: 40px;
    color: #FFD345;
}

.slick-next:before{
    font-size: 40px;
    color: #FFD345;
}

.slick-arrow {
    padding-bottom: 64px;
}

.brand .slick-list{
    padding-bottom: 64px;
}

.stf__wrapper{
    padding-bottom: 20%;
}

@media only screen and (max-width: 640px) {
    .slick-prev:before{
        font-size: 32px;
        color: #FFD345;
    }
    
    .slick-next:before{
        font-size: 32px;
        color: #FFD345;
    }
    
    .slick-arrow {
        padding-bottom: 50px;
    }
    
    .brand .slick-list{
        padding-bottom: 40px;
    }

}

.book_buttonP{
    background-image: url("../public/images/prev.png");
}

.book_buttonN{
    background-image: url("../public/images/next.png");
}

.stf__item div{
    height: 100%;
}
